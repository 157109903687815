import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm$userInfo$detailCo, _vm$userInfo$detailCo2, _vm$userInfo$detailCo3, _vm$userInfo$detailCo4, _vm$userInfo$detailCo5, _vm$userInfo$detailCo6, _vm$userInfo$detailCo7, _vm$userInfo$detailCo8, _vm$userInfo$detailCo9;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "user-info-card-container"
  }, [_c("ul", {
    staticClass: "user-info-content"
  }, [_c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    attrs: {
      label: "用户头像"
    }
  }, [_c("img", {
    staticClass: "user-avatar",
    attrs: {
      slot: "value",
      src: _vm.userInfo.headimgurl,
      alt: ""
    },
    slot: "value"
  })]), _c("UserInfoCardItem", {
    attrs: {
      label: "邀请人",
      value: _vm.userInfo.invitename
    },
    scopedSlots: _vm._u([_vm.userInfo.inviteuid ? {
      key: "value",
      fn: function fn() {
        return [_c("router-link", {
          attrs: {
            to: "/user/detail?uid=".concat(_vm.userInfo.inviteuid)
          }
        }, [_vm._v(_vm._s(_vm.userInfo.invitename))])];
      },
      proxy: true
    } : null], null, true)
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "CBK余额",
      value: _vm.userInfo.cbknum
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "KF3余额",
      value: _vm.userInfo.gbknum
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "KFC余额",
      value: _vm.userInfo.kncnum
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "USDT余额",
      value: _vm.userInfo.usdtnum
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "CBK余额总排名",
      value: _vm.userInfo.cbkrank
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "账号状态"
    },
    scopedSlots: _vm._u([{
      key: "value",
      fn: function fn() {
        return [_vm.userInfo.status === 0 ? _c("span", {
          staticClass: "color-green"
        }, [_vm._v("正常")]) : _vm.userInfo.status === 1 ? _c("span", {
          staticClass: "color-red"
        }, [_vm._v("停用")]) : _vm.userInfo.status === 2 ? _c("span", {
          staticClass: "color-red"
        }, [_vm._v("已删除")]) : _c("span", [_vm._v("---")])];
      },
      proxy: true
    }])
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "CBK发放状态"
    },
    scopedSlots: _vm._u([{
      key: "value",
      fn: function fn() {
        return [_vm.userInfo.cbkgrant === 1 ? _c("span", {
          staticClass: "color-red"
        }, [_vm._v("停发中")]) : _vm.userInfo.cbkgrant === 0 ? _c("span", {
          staticClass: "color-green"
        }, [_vm._v("发放中")]) : _c("span", [_vm._v("---")])];
      },
      proxy: true
    }])
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "CBK冻结余额",
      value: _vm.userInfo.cbkfreenum
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "CBK正常余额",
      value: _vm.userInfo.cbkavailablenum
    }
  })], 1), _c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    attrs: {
      label: "账号昵称",
      value: _vm.userInfo.username
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "账号ID",
      value: _vm.userInfo.uid
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "账号绑定邮箱",
      value: _vm.userInfo.email
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "账号绑定钱包",
      value: _vm.userInfo.walletaddress
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "账号绑定Facebook",
      value: _vm.userInfo.facebookid
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "账号绑定Twitter",
      value: _vm.userInfo.twitterid
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "账号绑定Discord",
      value: _vm.userInfo.discordid
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "账号绑定Apple",
      value: _vm.userInfo.appleid
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "账号绑定Google",
      value: _vm.userInfo.googleid
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "KN子钱包地址",
      value: _vm.userInfo.knswallet
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "城市",
      value: _vm.userInfo.city
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "国家/地区",
      value: _vm.userInfo.country
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "App版本",
      value: _vm.userInfo.version
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "App语言",
      value: _vm.userInfo.language
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "系统语言",
      value: _vm.userInfo.locale
    }
  })], 1), _c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    attrs: {
      label: "账号简介",
      value: _vm.userInfo.personalprofile
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "注册时间(GMT+8)",
      value: _vm.userInfo.createtime
    },
    scopedSlots: _vm._u([{
      key: "value",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.userInfo.createtime ? _vm.$moment.utc(_vm.userInfo.createtime).utcOffset(8).format("YYYY-MM-DD HH:mm:ss") : "---") + " ")];
      },
      proxy: true
    }])
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "最近一次登录时间(GMT+8)",
      value: _vm.userInfo.lastlogintime
    },
    scopedSlots: _vm._u([{
      key: "value",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.userInfo.lastlogintime ? _vm.$moment.utc(_vm.userInfo.lastlogintime).utcOffset(8).format("YYYY-MM-DD HH:mm:ss") : "---") + " ")];
      },
      proxy: true
    }])
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "最近一次活跃时间(GMT+8)",
      value: _vm.userInfo.lastrequesttime
    },
    scopedSlots: _vm._u([{
      key: "value",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.userInfo.lastrequesttime ? _vm.$moment.utc(_vm.userInfo.lastrequesttime).utcOffset(8).format("YYYY-MM-DD HH:mm:ss") : "---") + " ")];
      },
      proxy: true
    }])
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "最近一次登录设备机型",
      value: _vm.userInfo.lastlogindevice
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "最近一次登录IP",
      value: _vm.userInfo.lastloginip
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "最近一次登录User Agent",
      value: _vm.userInfo.lastloginagent
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "最近一次登录设备ID",
      value: _vm.userInfo.lastlogindeviceid
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "操作系统",
      value: _vm.userInfo.model
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "操作系统版本",
      value: _vm.userInfo.edition
    }
  }), _c("UserInfoCardItem", {
    staticClass: "min-h-120",
    attrs: {
      label: "平台",
      value: _vm.userInfo.os
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "影响力指数配置"
    },
    scopedSlots: _vm._u([{
      key: "value",
      fn: function fn() {
        return [_c("div", {
          staticClass: "effect_point"
        }, [_c("div", {
          staticClass: "text_wrap"
        }, [_vm._v(" " + _vm._s(!isNaN(_vm.userInfo.influencepoint) ? _vm.userInfo.influencepoint === 0 ? 0 : _vm.userInfo.influencepoint || "-" : "-") + " ")]), _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["userDetailInfluence:btn:modify"],
            expression: "['userDetailInfluence:btn:modify']"
          }],
          staticStyle: {
            "min-width": "auto",
            "border-top-left-radius": "0",
            "border-bottom-left-radius": "0"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleStopOpen(_vm.userInfo, "effect");
            }
          }
        }, [_vm._v(" 修改 ")])], 1)];
      },
      proxy: true
    }])
  })], 1)]), _c("ul", {
    staticClass: "user-info-content"
  }, [_c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    attrs: {
      label: "粉丝量",
      value: (_vm$userInfo$detailCo = _vm.userInfo.detailCountData) === null || _vm$userInfo$detailCo === void 0 ? void 0 : _vm$userInfo$detailCo.followernum
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "视频作品量",
      value: (_vm$userInfo$detailCo2 = _vm.userInfo.detailCountData) === null || _vm$userInfo$detailCo2 === void 0 ? void 0 : _vm$userInfo$detailCo2.videosnum
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "广场动态量",
      value: (_vm$userInfo$detailCo3 = _vm.userInfo.detailCountData) === null || _vm$userInfo$detailCo3 === void 0 ? void 0 : _vm$userInfo$detailCo3.dynamicnum
    }
  })], 1), _c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    attrs: {
      label: "视频作品总播放量",
      value: (_vm$userInfo$detailCo4 = _vm.userInfo.detailCountData) === null || _vm$userInfo$detailCo4 === void 0 ? void 0 : _vm$userInfo$detailCo4.views
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "视频作品总点赞量",
      value: (_vm$userInfo$detailCo5 = _vm.userInfo.detailCountData) === null || _vm$userInfo$detailCo5 === void 0 ? void 0 : _vm$userInfo$detailCo5.upvotes
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "视频作品总回复量",
      value: (_vm$userInfo$detailCo6 = _vm.userInfo.detailCountData) === null || _vm$userInfo$detailCo6 === void 0 ? void 0 : _vm$userInfo$detailCo6.interactionrate
    }
  })], 1), _c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    attrs: {
      label: "动态总浏览量",
      value: (_vm$userInfo$detailCo7 = _vm.userInfo.detailCountData) === null || _vm$userInfo$detailCo7 === void 0 ? void 0 : _vm$userInfo$detailCo7.dynamicview
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "动态总点赞量",
      value: (_vm$userInfo$detailCo8 = _vm.userInfo.detailCountData) === null || _vm$userInfo$detailCo8 === void 0 ? void 0 : _vm$userInfo$detailCo8.dynamicupvotes
    }
  }), _c("UserInfoCardItem", {
    attrs: {
      label: "动态总回复量",
      value: (_vm$userInfo$detailCo9 = _vm.userInfo.detailCountData) === null || _vm$userInfo$detailCo9 === void 0 ? void 0 : _vm$userInfo$detailCo9.dynamicinteractionrate
    }
  })], 1)]), _c("ul", [_c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    staticClass: "min-h-60",
    attrs: {
      label: "过往登录设备记录"
    }
  }, [_c("template", {
    slot: "value"
  }, [_vm.userInfo.logindevicehistory ? _c("div", _vm._l(_vm.userInfo.logindevicehistory, function (item, index) {
    return _c("span", {
      key: index,
      staticStyle: {
        flex: "none"
      }
    }, [_vm._v(" " + _vm._s(item) + ",   ")]);
  }), 0) : _c("span", [_vm._v(" --- ")])])], 2)], 1)]), _c("ul", [_c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    staticClass: "min-h-60",
    attrs: {
      label: "过往登录IP记录"
    }
  }, [_c("template", {
    slot: "value"
  }, [_vm.userInfo.loginiphistory ? _c("div", _vm._l(_vm.userInfo.loginiphistory, function (item, index) {
    return _c("span", {
      key: index,
      staticStyle: {
        flex: "none"
      }
    }, [_vm._v(_vm._s(item) + ",  ")]);
  }), 0) : _c("span", [_vm._v(" --- ")])])], 2)], 1)]), _c("ul", [_c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    staticClass: "min-h-60",
    attrs: {
      label: "风险提示"
    }
  }, [_c("div", {
    staticClass: "flex align-center",
    staticStyle: {
      gap: "10px"
    },
    attrs: {
      slot: "value"
    },
    slot: "value"
  }, [_vm.userInfo.prisonbreak ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    }
  }, [_vm._v(" 破解设备 ")]) : _vm._e(), _vm.userInfo.brushdevice ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    }
  }, [_vm._v(" 账号归属可疑刷子设备 ")]) : _vm._e(), _vm.userInfo.bruship ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    }
  }, [_vm._v(" 账号归属可疑刷子IP ")]) : _vm._e(), _vm.userInfo.refusedevice ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    }
  }, [_vm._v(" 空白App设备信息 ")]) : _vm._e(), _vm.userInfo.refuseagent ? _c("a-button", {
    attrs: {
      type: "danger",
      ghost: ""
    }
  }, [_vm._v(" 空白Web设备信息 ")]) : _vm._e(), !_vm.userInfo.prisonbreak && !_vm.userInfo.brushdevice && !_vm.userInfo.bruship && !_vm.userInfo.refusedevice && !_vm.userInfo.refuseagent ? _c("span", [_vm._v("---")]) : _vm._e()], 1)])], 1)]), _c("ul", [_c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    staticClass: "min-h-60",
    attrs: {
      label: "用户评级"
    }
  }, [_c("div", {
    staticClass: "flex align-center",
    staticStyle: {
      gap: "10px"
    },
    attrs: {
      slot: "value"
    },
    slot: "value"
  }, [_vm._v(" " + _vm._s(_vm.userInfo.usergrade) + " ")])])], 1)]), _c("ul", [_c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    staticClass: "min-h-60",
    attrs: {
      label: "自定义用户标签"
    },
    scopedSlots: _vm._u([{
      key: "value",
      fn: function fn() {
        return [_c("div", [_c("div", [_c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["check:user:update"],
            expression: "['check:user:update']"
          }],
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleStopOpen(_vm.userInfo, "tag");
            }
          }
        }, [_vm._v("编辑用户标签")])], 1), _c("div", {
          staticStyle: {
            "margin-top": "10px"
          }
        }, _vm._l(_vm.tags, function (tag, index) {
          return _c("a-tag", {
            key: index
          }, [_vm._v(_vm._s(tag.tagname))]);
        }), 1)])];
      },
      proxy: true
    }])
  })], 1)]), _c("ul", [_c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    staticClass: "min-h-60",
    attrs: {
      label: "备注"
    },
    scopedSlots: _vm._u([{
      key: "value",
      fn: function fn() {
        return [_c("div", [_c("div", {
          staticStyle: {
            display: "flex",
            gap: "10px"
          }
        }, [_c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["check:user:update"],
            expression: "['check:user:update']"
          }],
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleStopOpen(_vm.userInfo, "remark");
            }
          }
        }, [_vm._v("编辑备注")]), _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["check:user:update"],
            expression: "['check:user:update']"
          }],
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleStopOpen(_vm.userInfo, "remarkLog");
            }
          }
        }, [_vm._v("查看历史备注")])], 1), _c("div", {
          staticStyle: {
            "margin-top": "10px"
          }
        }, [_vm._v(_vm._s(_vm.notes))])])];
      },
      proxy: true
    }])
  })], 1)]), _c("ul", [_c("li", {
    staticClass: "item-wrapper"
  }, [_c("UserInfoCardItem", {
    staticClass: "min-h-60",
    attrs: {
      label: "操作执行"
    },
    scopedSlots: _vm._u([{
      key: "value",
      fn: function fn() {
        return [_vm.userInfo.status == 0 ? _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["check:user:update"],
            expression: "['check:user:update']"
          }],
          attrs: {
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.handleStopOpen(_vm.userInfo, "status");
            }
          }
        }, [_vm._v("停用")]) : _vm._e(), _vm.userInfo.status == 1 ? _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["check:user:update"],
            expression: "['check:user:update']"
          }],
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleStopOpen(_vm.userInfo, "status");
            }
          }
        }, [_vm._v("启用")]) : _vm._e(), _vm.userInfo.cbkgrant === 0 ? _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["check:user:update"],
            expression: "['check:user:update']"
          }],
          attrs: {
            type: "danger",
            ghost: ""
          },
          on: {
            click: function click($event) {
              return _vm.handleStopOpen(_vm.userInfo, "cbkgrant");
            }
          }
        }, [_vm._v("CBK停发")]) : _vm.userInfo.cbkgrant === 1 ? _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["check:user:update"],
            expression: "['check:user:update']"
          }],
          attrs: {
            type: "primary",
            ghost: ""
          },
          on: {
            click: function click($event) {
              return _vm.handleStopOpen(_vm.userInfo, "cbkgrant");
            }
          }
        }, [_vm._v("CBK续发")]) : _vm._e(), _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["check:user:update"],
            expression: "['check:user:update']"
          }],
          attrs: {
            type: "dashed"
          },
          on: {
            click: function click($event) {
              return _vm.handleStopOpen(_vm.userInfo, "freeze");
            }
          }
        }, [_vm._v("CBK冻结")]), _c("a-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleStopOpen(_vm.userInfo, "operateLog");
            }
          }
        }, [_vm._v("查看操作日志")])];
      },
      proxy: true
    }])
  })], 1)]), _c("DPopup", {
    attrs: {
      modalWidth: ["remarkLog", "operateLog"].includes(_vm.action) ? "960px" : "416px",
      title: _vm.modalTitle,
      comVisible: _vm.statusVisible,
      isClosable: ["remarkLog", "operateLog"].includes(_vm.action)
    },
    on: {
      cancel: _vm.handleResetCancle
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_vm.action == "freeze" ? _c("Freeze", {
          attrs: {
            uid: _vm.updateStatusId
          },
          on: {
            cancel: _vm.handleResetCancle,
            submit: function submit() {
              _vm.handleResetCancle();
              _vm.refresh();
            }
          }
        }) : _vm.action == "remark" ? _c("Remark", {
          attrs: {
            item: {
              uid: _vm.userInfo.uid,
              notes: _vm.notes
            }
          },
          on: {
            cancel: _vm.handleResetCancle,
            submit: function submit() {
              _vm.handleResetCancle();
              _vm.refresh("remark");
            }
          }
        }) : _vm.action == "remarkLog" || _vm.action == "operateLog" ? _c("Log", {
          attrs: {
            action: _vm.action,
            editId: _vm.updateStatusId
          }
        }) : _vm.action == "tag" ? _c("Tag", {
          attrs: {
            uid: _vm.updateStatusId,
            tags: _vm.tagids
          },
          on: {
            cancel: _vm.handleResetCancle,
            submit: function submit() {
              _vm.handleResetCancle();
              _vm.refresh("tag");
            }
          }
        }) : _vm.action == "effect" ? _c("EffectPower", {
          attrs: {
            uid: _vm.updateStatusId,
            effect: _vm.userInfo.influencepoint
          },
          on: {
            cancel: _vm.handleResetCancle,
            submit: function submit() {
              _vm.handleResetCancle();
              _vm.refresh();
            }
          }
        }) : _c("RsetStatus", {
          attrs: {
            updateStatus: _vm.updateStatus,
            loading: _vm.upLoading,
            type: _vm.action
          },
          on: {
            handleAddCancle: _vm.handleResetCancle,
            handleAddSubmit: _vm.handleResetSubmitSuccess
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
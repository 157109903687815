var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      selfUpdate: true
    }
  }, [_c("a-form-item", {
    attrs: {
      prop: "remark",
      rules: []
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["tagids", {
        rules: [{
          required: false
        }],
        initialValue: _vm.tags
      }],
      expression: "[\n              'tagids',\n              {\n                rules: [{ required: false }],\n                initialValue: tags,\n              },\n            ]"
    }],
    staticClass: "cus-select",
    staticStyle: {
      width: "368px"
    },
    attrs: {
      mode: "multiple",
      dropdownClassName: "cus-select",
      allowClear: "",
      "show-search": "",
      size: "large",
      placeholder: "Search",
      "filter-option": _vm.filterOption,
      "not-found-content": _vm.uloading ? undefined : null
    }
  }, [_vm.uloading ? _c("a-spin", {
    attrs: {
      slot: "notFoundContent",
      size: "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.tagSource, function (d) {
    return _c("a-select-option", {
      key: d.tagid,
      staticClass: "user-select-item",
      attrs: {
        title: d.tagname,
        value: d.tagid
      }
    }, [_vm._v(_vm._s(d.tagname) + " ")]);
  })], 2)], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.submiting
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("提交")])], 1)])], 1)], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
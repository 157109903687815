import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import { SysAccountApi } from "@/api";
export default {
  name: "userRemark",
  components: {},
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      submiting: false,
      info: _objectSpread({}, this.item)
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    cancel: function cancel() {
      this.$emit("cancel");
    },
    submit: function submit() {
      var _this = this;
      this.$refs.userRemarkForm.validate(function (valid) {
        if (valid) {
          _this.submiting = true;
          SysAccountApi.addUserNotes({
            uid: _this.item.uid,
            notes: _this.info.notes
          }).then(function (res) {
            _this.toast("\u64CD\u4F5C\u6210\u529F", "success");
            _this.$emit("submit");
          }).catch(function (err) {
            _this.toast((err === null || err === void 0 ? void 0 : err.msg) || "\u64CD\u4F5C\u5931\u8D25", "error");
          }).finally(function () {
            _this.submiting = false;
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
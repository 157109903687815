var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer-header"
  }, [_c("a-table", {
    attrs: {
      columns: _vm.logColumns,
      pagination: false,
      scroll: {
        x: "100%",
        y: "65vh"
      },
      loading: _vm.loading,
      "data-source": _vm.dataSource,
      rowKey: "id"
    },
    scopedSlots: _vm._u([{
      key: "createtime",
      fn: function fn(text) {
        return [_vm._v(" " + _vm._s(_vm.$moment(text * 1000).utcOffset(8).format("YYYY-MM-DD HH:mm:ss")) + " ")];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form-model", {
    ref: "userRemarkForm",
    attrs: {
      labelCol: {
        style: "width: 65px"
      },
      model: _vm.info
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "备注",
      prop: "notes",
      rules: [{
        required: false,
        message: "备注不能为空"
      }, {
        max: 500,
        message: "备注长度不能超过500字符"
      }]
    }
  }, [_c("div", {
    staticClass: "input-num-tip",
    attrs: {
      "data-num": "".concat((_vm.info.notes || "").length, "/500")
    }
  }, [_c("a-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入",
      "show-count": "",
      maxlength: 500
    },
    model: {
      value: _vm.info.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "notes", $$v);
      },
      expression: "info.notes"
    }
  })], 1)])], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.submiting
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("提交")])], 1)])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
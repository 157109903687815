import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import { SysAccountApi } from "@/api";
import { remarkLogColumns, operateLogColumns } from "../config";
export default {
  name: "userLog",
  components: {},
  props: {
    editId: [String, Number],
    action: {
      type: String,
      default: function _default() {
        return "remarkLog";
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      logColumns: this.action == "remarkLog" ? remarkLogColumns : operateLogColumns,
      dataSource: [],
      // 模板列表
      total: 0,
      page: 1,
      size: 30
    };
  },
  computed: {},
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      var params = {
        uid: this.editId,
        page: this.page,
        size: this.size
      };
      var method = this.action == 'remarkLog' ? 'getUserNotesHistory' : 'userOperationHistory';
      SysAccountApi[method](_objectSpread({}, params)).then(function (res) {
        _this.dataSource = (res === null || res === void 0 ? void 0 : res.list) || [];
        _this.total = res.total || 0;
      }).catch(function () {
        _this.dataSource = [];
        _this.total = 0;
      }).finally(function () {
        _this.loading = false;
      });
    },
    cancel: function cancel() {
      this.$emit("cancel");
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    }
  }
};
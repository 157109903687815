var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("p", [_vm._v("此项为运营临时干预影响力指数展示用，提交数值后，将以此指数展示该创作者的影响力指数。")]), _c("a-form", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      form: _vm.form,
      selfUpdate: true
    }
  }, [_c("a-form-item", {
    staticClass: "influenceWrap",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: ""
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["influencepoint", {
        // rules: [{ required: true, message: '请输入影响力指数' }],
        initialValue: _vm.effect
      }],
      expression: "[\n            'influencepoint',\n            {\n              // rules: [{ required: true, message: '请输入影响力指数' }],\n              initialValue: effect,\n            },\n          ]"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "number",
      placeholder: "请输入影响力指数"
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.submiting
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("提交")])], 1)])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { SysAccountApi } from "@/api";
export default {
  name: "userTag",
  components: {},
  props: {
    uid: {
      type: [String, Number],
      default: ""
    },
    tags: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      form: this.$form.createForm(this, {
        name: this.uid
      }),
      submiting: false,
      uloading: false,
      spinning: false,
      tagSource: []
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    this.find();
  },
  methods: {
    find: function find() {
      var _this = this;
      this.spinning = true;
      SysAccountApi.tagList({}).then(function (data) {
        _this.tagSource = data;
      }).catch(function () {
        _this.tagSource = [];
      }).finally(function () {
        _this.spinning = false;
      });
    },
    cancel: function cancel() {
      this.$emit("cancel");
    },
    submit: function submit() {
      var _this2 = this;
      this.form.validateFields(function (err, values) {
        if (!err) {
          _this2.submiting = true;
          SysAccountApi.userTagSync(_objectSpread({
            uid: _this2.uid
          }, values)).then(function (res) {
            _this2.toast("\u64CD\u4F5C\u6210\u529F", "success");
            _this2.$emit("submit");
          }).catch(function (err) {
            _this2.toast((err === null || err === void 0 ? void 0 : err.msg) || "\u64CD\u4F5C\u5931\u8D25", "error");
          }).finally(function () {
            _this2.submiting = false;
          });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  }
};
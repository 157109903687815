import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-card", [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("div", {
    staticClass: "card-header"
  }, [_c("h2", [_vm._v(_vm._s("\u57FA\u672C\u4FE1\u606F-".concat(_vm.user_info.username)))]), _c("router-link", {
    attrs: {
      to: "/cbkflow?uid=".concat(_vm.user_info.uid)
    }
  }, [_vm._v("查看CBK流水")]), _c("a", {
    attrs: {
      href: "".concat(_vm.fanshoodurl, "hood/profile/").concat(_vm.uid),
      target: "_black"
    }
  }, [_vm._v("Profile")])], 1), _c("UserInfoCard", {
    attrs: {
      userInfo: _vm.user_info,
      notes: _vm.notes,
      tags: _vm.userTags
    },
    on: {
      refresh: function refresh(field) {
        field == "remark" ? _vm.getUserNotes() : field == "tag" ? _vm.getUserTags() : _vm.getUserInfo();
      }
    }
  })], 1), _c("div", {
    staticClass: "card-header"
  }, [_c("h2", [_vm._v("好友邀请列表")]), _c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.downloading
    },
    on: {
      click: _vm.download
    }
  }, [_vm._v("导出")])], 1), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.uid;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "username" ? _c("span", [_c("router-link", {
            attrs: {
              to: "/user/detail?uid=".concat(record.uid)
            }
          }, [_vm._v(_vm._s(text))])], 1) : key == "invitetime" ? _c("span", [_vm._v(_vm._s(_vm.$moment(text * 1000).utcOffset(8).format("YYYY-MM-DD HH:mm:ss")))]) : key == "prisonbreak" ? _c("span", [_vm._v(_vm._s(text == 1 ? "是" : "否"))]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
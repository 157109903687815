import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import UserInfoCardItem from "./UserInfoCardItem.vue";
import { SysAccountApi } from "@/api";
import RsetStatus from "./RestStatus.vue";
import Freeze from "./Freeze.vue";
import Remark from "./Remark.vue";
import Log from "./Log.vue";
import Tag from "./Tag.vue";
import EffectPower from "./EffectPower.vue";
export default {
  name: "UserInfoCard",
  components: {
    UserInfoCardItem: UserInfoCardItem,
    RsetStatus: RsetStatus,
    Freeze: Freeze,
    Remark: Remark,
    Log: Log,
    Tag: Tag,
    EffectPower: EffectPower
  },
  props: {
    userInfo: {
      type: Object,
      default: function _default() {}
    },
    notes: {
      type: [String],
      default: ""
    },
    tags: {
      type: [Array],
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    modalTitle: function modalTitle() {
      return this.action == "freeze" ? "CBK冻结操作" : this.action == "remark" ? "编辑当前备注" : this.action == "operateLog" ? "查看操作日志" : this.action == "remarkLog" ? "查看历史备注" : this.action == "effect" ? "影响力指数配置" : this.action == "tag" ? "编辑用户标签" : "提示";
    },
    tagids: function tagids() {
      return (this.tags || []).map(function (it) {
        return it.tagid;
      }) || [];
    }
  },
  data: function data() {
    return {
      updateStatus: 0,
      updateStatusId: undefined,
      action: "",
      statusVisible: false,
      upLoading: false
    };
  },
  methods: {
    handleStopOpen: function handleStopOpen(val) {
      var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "status";
      if (field == "status") {
        this.updateStatus = val.status;
        this.updateStatusId = val.id;
      } else if (field == "cbkgrant") {
        this.updateStatus = val.cbkgrant == 0 ? 1 : 0;
        this.updateStatusId = val.uid;
      } else if (field == "freeze") {
        this.updateStatusId = val.uid;
      } else {
        this.updateStatusId = val.uid;
      }
      this.action = field;
      this.statusVisible = true;
    },
    handleResetCancle: function handleResetCancle() {
      this.statusVisible = false;
    },
    handleResetSubmitSuccess: function handleResetSubmitSuccess() {
      var _this = this;
      this.upLoading = true;
      var method = "upUserFindpage";
      var params = {
        id: this.updateStatusId
      };
      if (this.action == "cbkgrant") {
        method = "toggleCBKGrant";
        params = {
          uid: this.updateStatusId,
          status: this.updateStatus
        };
      }
      SysAccountApi[method](params).then(function (res) {
        _this.toast("操作成功", "success");
        _this.statusVisible = false;
        _this.$emit("refresh");
      }).catch(function () {}).finally(function () {
        _this.upLoading = false;
      });
    },
    refresh: function refresh() {
      var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "all";
      this.$emit("refresh", field);
    }
  }
};